import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { MonitCashInfo } from 'sharedv2/manifest';

import * as serviceWorker from './serviceWorker';

if (
  ['cash.monitpay.com', 'app.monitpay.com', 'monit-webapp.vercel.app'].includes(
    window.location.hostname
  )
) {
  Sentry.init({
    dsn: 'https://585bc0899f0245d5be808d80563553b2@o1034369.ingest.sentry.io/6000946',
    integrations: [Sentry.browserTracingIntegration()],
    ignoreErrors: ['ChunkLoadError'],
    release: `${MonitCashInfo.version}+${MonitCashInfo.number}`,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,
  });
}

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'cashadmin') {
    return import('./cashadmin/App');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'cash') {
    return import('./cash/App');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'pay') {
    return import('./payment/App');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'portal') {
    return import('./vendor-portal/App');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'card') {
    return import('./card/App');
  } else {
    return Promise.reject(
      new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET)
    );
  }
}

importBuildTarget().then(({ default: Environment }) => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(<Environment />);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
